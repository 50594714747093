import React, { useState } from "react";
import "./App.css";
import styled from "styled-components";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import AtlasLogo from "./orion-192px.png";
import AtlasPlatform from "./platform.png";
import * as emailjs from "emailjs-com";

export const App = () => {
    const [name, updateName] = useState("");
    const [email, updateEmail] = useState("");
    const [phone, updatePhone] = useState("");
    const [city, updateCity] = useState("");
    const [message, updateMessage] = useState("");

    const clearFields = () => {
        updateName("");
        updateEmail("");
        updatePhone("");
        updateCity("");
        updateMessage("");
    };

    const onSend = (e) => {
        e.preventDefault();

        console.log("called");
        emailjs
            .send(
                "service_j7kico5",
                "template_bwphuc8",
                {
                    from_name: name,
                    phone: phone,
                    email: email,
                    city: city,
                    message: message,
                },
                "user_vVhoquD33ItbUJ9GdUw3D"
            )
            .then((result) => clearFields());
    };

    return (
        <AppBackground>
            <Navbar>
                <Logo src={AtlasLogo} alt="atlas-logo" />
                <H1>Atlas Crime</H1>
            </Navbar>
            <Sections>
                <SectionRow>
                    <HalfSectionLeft>
                        <H1>Keep Safe What Matters Most</H1>
                        <H4WMargin>
                            Our mission is to support all Ukrainians as they keep their families, communities and
                            businesses safe.
                        </H4WMargin>
                        <H1>Бережіть те, що найважливіше</H1>
                        <H4>
                            Наша місія – підтримати всіх українців, щоб вони убезпечили свої сім’ї, громади та бізнес.
                        </H4>
                    </HalfSectionLeft>
                    <HalfSection>
                        <PlatformPicture src={AtlasPlatform} alt="atlas-platform" />
                    </HalfSection>
                </SectionRow>
                {/* <Section>
                    <H1WMargin>Our Features</H1WMargin>
                </Section> */}
                <Section>
                    <H1WMargin>Sign Up / зареєструватися</H1WMargin>
                    <P>
                        <b>For Ukrainians only:</b> fill out form to sign up now for free. Our team will contact you via
                        email within 24 hours to confirm your account.
                    </P>
                    <PWMargin>
                        <b>Тільки для українців:</b> заповніть форму, щоб зареєструватися безкоштовно. Наша команда
                        зв’яжеться з вами електронною поштою протягом 24 годин, щоб підтвердити ваш обліковий запис.
                    </PWMargin>
                    <FormContainer>
                        <PWMargin>Name / ім'я</PWMargin>
                        <ContactInput
                            placeholder="Type Your Name Here"
                            value={name}
                            onChange={(e) => updateName(e.target.value)}
                        />
                        <PWMargin>Email / електронна пошта</PWMargin>
                        <ContactInput
                            placeholder="Type Your Email Here"
                            value={email}
                            onChange={(e) => updateEmail(e.target.value)}
                        />
                        <PWMargin>Phone Number / телефонний номер</PWMargin>
                        <ContactInput
                            placeholder="Type Your Phone Number Here"
                            value={phone}
                            onChange={(e) => updatePhone(e.target.value)}
                        />
                        <PWMargin>City / місто</PWMargin>
                        <ContactInput
                            placeholder="Type Your City Name Here"
                            value={city}
                            onChange={(e) => updateCity(e.target.value)}
                        />
                        <PWMargin>
                            How can our app help you? (Optional) / <br />
                            Як наш додаток може допомогти вам? (Необов'язково)
                        </PWMargin>
                        <ContactTextArea
                            placeholder="Type Your Message Here"
                            autoSize={{ minRows: 1, maxRows: 4 }}
                            value={message}
                            onChange={(e) => updateMessage(e.target.value)}
                        />
                    </FormContainer>
                    <ContactActionButton onClick={(e) => onSend(e)}>Sign Up / зареєструватися</ContactActionButton>
                </Section>
            </Sections>
        </AppBackground>
    );
};

const AppBackground = styled.div`
    background: #f5f5f5;
    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
`;

const Navbar = styled.div`
    height: 100px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
`;

const HalfSection = styled.div`
    width: 80%;

    @media (min-width: 800px) {
        width: 40%;
    }
`;

const HalfSectionLeft = styled(HalfSection)`
    margin-right: 0;

    @media (min-width: 800px) {
        margin-right: 20%;
    }
`;

const Logo = styled.img`
    height: 42px;
    width: 42px;
    background: blue;
    margin-right: 20px;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(19, 25, 44, 0.25));
`;

const PlatformPicture = styled.img`
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;

    @media (min-width: 800px) {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const Sections = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Section = styled.div`
    min-height: 100vh;
    width: 70%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const SectionRow = styled(Section)`
    flex-direction: column;

    @media (min-width: 800px) {
        flex-direction: row;
    }
`;

const H1 = styled.h1`
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 28px;
    text-align: center;

    @media (min-width: 800px) {
        text-align: left;
    }
`;

const H1WMargin = styled(H1)`
    margin-bottom: 25px;
`;

const H4 = styled.h4`
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
    text-align: center;

    @media (min-width: 800px) {
        text-align: left;
    }
`;

const H4WMargin = styled(H4)`
    margin-bottom: 40px;
`;

const P = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    max-width: 600px;
    text-align: center;

    @media (min-width: 800px) {
        text-align: left;
    }
`;

const PWMargin = styled(P)`
    margin-top: 10px;
`;

const FormContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;

    @media (min-width: 800px) {
        width: 50%;
    }
`;

const ContactInput = styled(Input)`
    font-family: "Inter", sans-serif;
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    border-radius: 8px;
    border: 1px solid #d5d8e9;
`;

const ContactTextArea = styled(TextArea)`
    font-family: "Inter", sans-serif;
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    border-radius: 8px;
    border: 1px solid #d5d8e9;
`;

const ContactActionButton = styled.button`
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 20px;
    width: 90%;
    padding: 12px;
    border-radius: 25px;
    background: #303551;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 50px;

    @media (min-width: 800px) {
        width: 300px;
    }
`;
